import React, { Component } from 'react';
import './App.css';
import wordList from './words.json'

class App extends Component {
  constructor (props) {
    super(props);

    const length = wordList[Math.floor(Math.random() * wordList.length)].length;

    this.state = {
      words: this.getWordListWithLength(length),
      guessed: new Set(),
      error: "",
      mistakes: 0,
      instruction: "Type Anywhere to Guess",
      pattern: (new Array(length)).fill('_').join(''),
      won: false
    }

    document.onkeydown = (e) => {
      const letter = e.key.toLowerCase();
      if (/^[a-z\- ]$/.test(letter)) {
        this.guess(e.key)
      }
    }
  }

  render = () => (
    <div className="App" style={{
      width: `calc(100vw - ${this.state.won ? 0 : 50 * this.state.mistakes}px)`,
      height: `calc(100vh - ${this.state.won ? 0 : 50 * this.state.mistakes}px)`,
      border: `#000 ${25 * (this.state.won ? 0 : this.state.mistakes)}px solid`,
      position: 'relative',
      zIndex: 1000,
      transition: 'border 0.3s, width 0.3s, height 0.3s',
    }}>
      <div style={{ position: 'absolute', top: (this.state.won ? 0 : -25) * this.state.mistakes, left: (this.state.won ? 0 : -25) * this.state.mistakes, width: '100vw', height: '100vh', transition: 'left 0.3s, top 0.3s' }}>
        {
          !this.state.won ?
          <div>
            <p dangerouslySetInnerHTML={{__html: this.state.instruction}}></p>
            <h1>{ this.state.pattern.substring(0, this.state.pattern.length - 1) }<span>{this.state.pattern[this.state.pattern.length - 1]}</span></h1>
            <h2>Guessed ({this.state.guessed.size}): {Array.from(this.state.guessed).join(' ')}</h2> <br />
            { this.state.error
              ? (<h2>{this.state.error}</h2>)
              : null}
          </div>
          : <div>
              <h1 style={{ position: 'absolute', width: '100vw', height: '100vh', transform: `scale(${1/this.state.won})`, transition: 'transform 0.2s linear'}}>{this.state.pattern}<br /><br />You won!{ (new Array(this.state.won)).fill(' !').join('') }</h1>
            </div>
        }
      </div>
    </div>
  );

  guess = (letter) => {
    this.setState({ instruction: '&nbsp;' });

    if (this.state.guessed.has(letter)) {
      return this.setState({ error: `You already guessed "${letter}", ${this.getInsult()}` });
    }

    this.setState({ error: '' }, () => {
      this.state.guessed.add(letter);

      this.filterWordList(letter);
    })
  }

  getWordListWithLength = (length) => {
    return wordList.filter(word => word.length == length);
  }

  getInsult = () => {
    const insults = [
      'idiot',
      'stupid',
      'bucket head',
      'did you hit your head?',
      'are you dumb?',
      'what\'s wrong with you?',
      'dummy',
      'airbrain',
      'zero brain',
      'brainless',
      'get a brain',
      'think!',
      'did you really think that would work?',
      'did you really expect that to work?',
      'you\'re dumber than I thought',
      'got rocks in your head?',
      'is this funny to you?',
      'I think your neuron broke',
      'maybe you should submit a bug report to your creator'
    ];
    return insults[Math.floor(Math.random() * insults.length)]
  }

  filterWordList = (letter) => {
    const numberWordsWithPattern = {};
    const letterSet = new Set([letter]);

    let maxPatternCount = 0;
    let maxPattern = "";
    
    for (const word of this.state.words) {
      const pattern = this.getPattern(word, letterSet);
      if (!numberWordsWithPattern[pattern]) {
        numberWordsWithPattern[pattern] = 0;
      }
      ++numberWordsWithPattern[pattern];

      if (numberWordsWithPattern[pattern] > maxPatternCount) {
        maxPatternCount = numberWordsWithPattern[pattern];
        maxPattern = pattern;
      }
    }

    console.log(this.state.words, maxPatternCount, this.state.words.map(word => this.getPattern(word, letterSet)), maxPattern)

    if (maxPattern.match(/[^_]/g) === null) {
      this.setState({ error: `The letter "${letter}" isn't there, ${this.getInsult()}`, mistakes: this.state.mistakes + 1 });
    }
    this.setState({ words: this.state.words.filter(word => maxPattern === this.getPattern(word, letterSet)) }, () => {
      this.setState({ pattern: this.getPattern(this.state.words[0], this.state.guessed), won: this.getPattern(this.state.words[0], this.state.guessed) === this.state.words[0] ? 1 : false })
      if (this.getPattern(this.state.words[0], this.state.guessed) === this.state.words[0]) {
        setInterval(() => this.setState({ won: this.state.won + 1 }), 100)
        setTimeout(() => window.location.reload(), 4000);
      }
    });
  }

  getPattern = (word, letters) => (
    word.split('').map(char => letters.has(char) ? char : '_').join('')
  )
}

export default App;
